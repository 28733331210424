<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="institutionApi.isError" :api="institutionApi" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.select_school") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-2">
            <div
              v-for="(school, i) in schools"
              :key="i"
              class="topic d-flex align-center"
              :class="selected == school.id ? 'selected-sch' : ''"
              @click="
                () => {
                  selected = school.id;
                }
              "
            >
              <v-img
                v-if="school.logoUrl"
                :src="school.logoUrl"
                :max-width="120"
              ></v-img>
              <v-avatar
                v-else
                color="green"
                size="70"
                class="text-uppercase white--text my-2 mx-3"
              >
                {{ school.name.slice(0, 2) }}
              </v-avatar>
              <div class="mx-2">
                <div class="text-h5 text-left">{{ school.name }}</div>
                <div class="text-h6 text-left">{{ school.town }}</div>
              </div>
            </div>
            <div class="text-center mt-6">
              <v-btn
                class="text-h6 px-10 py-3"
                color="yellow"
                large
                @click="laodInstitution()"
              >
                OK
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    institutionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    selected: null,
    schools: [],
    auth_data: null,
  }),
  created() {
    this.institutionApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/institution";

    this.institutionApi.callbackReset = () => {
      this.institutionApi.isLoading = true;
      this.institutionApi.isError = false;
    };

    this.institutionApi.callbackError = (e) => {
      this.institutionApi.isLoading = false;
      this.institutionApi.isError = true;
      this.institutionApi.error = e;
    };
    this.institutionApi.callbackSuccess = (resp) => {
      this.institutionApi.isLoading = false;
      this.schools = resp.Institution;
    };
  },
  mounted() {
    this.auth_data = this.$_.cloneDeep(this.auth);
    this.selected = this.auth.Institution.id;
    this.$api.fetch(this.institutionApi);
  },
  methods: {
    laodInstitution() {
      this.institutionApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/institution/load";

      this.institutionApi.callbackReset = () => {
        this.institutionApi.isLoading = true;
        this.institutionApi.isError = false;
      };

      this.institutionApi.callbackError = (e) => {
        this.institutionApi.isLoading = false;
        this.institutionApi.isError = true;
        this.institutionApi.error = e;
      };
      this.institutionApi.callbackSuccess = (resp) => {
        this.institutionApi.isLoading = false;
        this.auth_data.Institution = resp.Institution;
        this.auth_data.Group = resp.Group;
        this.$store.commit("updateAuth", this.auth_data);
        this.exit();
      };
      this.institutionApi.params = {
        institutionId: this.selected,
      };
      this.$api.fetch(this.institutionApi);
    },
    exit() {
      this.$router.push({
        name: "PageEventSchool",
      });
    },
  },
};
</script>

    <style scoped>
.topic {
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  background-color: #e3ceb1;
}

.sch {
  border: #ccaf8b 4px solid;
}

.selected-sch {
  border: #23c4bc 5px solid;
}
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.list-card {
  padding: 10px 10px 10px 20px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
  text-align: center;
}

.coin-img {
  background-color: #6b583c;
}
</style>